import React, { useMemo } from 'react';
import { SquareButton } from '../../../Core/FormInput/AppButton';
import ReportGijgoGrid from '../../internal/ReportsScreens/GijgoForReportsScreen/index';
import { Grid, Typography } from '@material-ui/core';
import ReactDOM from 'react-dom';
import EditIcon from '@material-ui/icons/Edit';
// import DiagnosticFaultsModal from '../DiagnosticFaultsModal';
import { useState } from 'react';
import DialogComp from '../../../Core/Modal/dialogModal';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import { useEffect } from 'react';
import PartsAddUpdate from './PartsAddUpdate';

const baseColumns = [
    { field: 'action', sortable: false, title: ``, width: 70, filterable: false },
    { field: 'partNumber', sortable: false, title: `Part Number`, width: 180 },
    { field: 'description', sortable: false, title: `Part Description` },
    { field: 'isActive', sortable: false, title: `Is Active` }
];

const crumbs = (path) => [
    { name: 'Home', path: '' },
    { name: 'List', active: true },
    { name: 'Parts', active: true }
];

const PartList = (props) => {
    const [state, setState] = useState({
        showModal: false
    });

    const handleClose = (res) => {
        console.log(res, 'res');
        setState((st) => {
            let newSt = { ...st };
            newSt.showModal = false;
            if (res) {
                newSt.isReload = new Date();
            }
            return newSt;
        });
    };

    const handleEdit = (val) => {
        setState((st) => ({
            ...st,
            showModal: true,
            partID: val?.partID
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center">
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => handleEdit(record)}
                >
                    <EditIcon fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        let colAction = col.find((m) => m.field === 'action');
        if (colAction) {
            colAction.renderer = editButton;
        }

        return col;
    }, []);

    const baseUrl = 'AdminParts/Parts_List';

    return (
        <div className="report-screen-container">
            <Grid container justify="space-between">
                <Grid item>
                    <BreadCrumbs crumbs={crumbs()} />
                </Grid>
                <Grid item style={{ paddingTop: '5px' }}>
                    <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '0%',
                            // width: '100px',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 10px 0px 10px'
                        }}
                        onClick={handleEdit}
                    >
                        <span style={{ color: 'white', fontSize: 12 }}>+ Add Part</span>
                    </SquareButton>
                </Grid>
            </Grid>
            <ReportGijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res?.data?.total
                })}
                columns={column}
                getUrl={baseUrl}
                isReload={state.isReload}
                isShowTotal={false}
            />
            {state.showModal ? (
                <DialogComp
                    title={`${state.partID ? `Update` : `Add`} Parts`}
                    maxWidth="sm"
                    onClose={() =>
                        setState((st) => ({
                            ...st,
                            showModal: false
                        }))
                    }
                    fullWidth
                >
                    <PartsAddUpdate onClose={() => handleClose(true)} partID={state.partID} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default PartList;
