import { useEffect, useState } from 'react';
import { DefaultButton, PrimaryButton, SecondaryButton } from '../FormInput';
import { useHistory } from 'react-router-dom';
import './BasicButtonGroup.scss';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useWindowSize } from '../Controls/ScreenResolution';

export const SpaceType = {
    start: 'start',
    spaceAround: 'space-around',
    spaceBetween: 'space-between'
};

function scrollbarVisible(element) {
    return element?.scrollWidth > element?.clientWidth;
}

const scrollNext = (id) => {
    document.getElementById('WorkFlow-Btn').scrollBy({ left: 100, top: 0, behavior: 'smooth' });
};

const scrollPrev = (id) => {
    document.getElementById('WorkFlow-Btn').scrollBy({ left: -100, top: 0, behavior: 'smooth' });
};

const BasicButtonGroup = ({
    className = '',
    buttons = [],
    btnSelectedId,
    selectedId = 0,
    triggerButtonClickOnLoad = true,
    onClick,
    spaceType = SpaceType.start,
    margin = 0,
    color = 'secondary',
    allGrid
}) => {
    let WorkFlowBtnElementscrollWidth = document.getElementById('WorkFlow-Btn');

    const [state, setState] = useState({
        selectedId,
        buttons: [],
        basicStyle: { minHeight: 30, display: 'flex', justifyContent: spaceType, margin, overflow: 'auto' },
        showHighlightedButton: true
    });

    const [WindowWidths, WindowHeights] = useWindowSize();

    useEffect(() => {
        console.log(selectedId, 'selectedId');
    }, [selectedId]);
    useEffect(() => {
        console.log(state.selectedId, 'state.selectedId');
    }, [state.selectedId]);

    useEffect(() => {
        if (triggerButtonClickOnLoad && onClick) {
            if (btnSelectedId !== 'All' && btnSelectedId) {
                const hasSelected = buttons.filter((b) => b.id === +btnSelectedId).length === 1;
                hasSelected ? buttonClicked(buttons.filter((b) => b.id === +btnSelectedId)[0]) : buttons.length > 0 && buttonClicked(buttons[0]);
            }
            if (!allGrid) {
                const hasSelected = buttons.filter((b) => b.id === state.selectedId).length === 1;
                hasSelected ? buttonClicked(buttons.filter((b) => b.id === state.selectedId)[0]) : buttons.length > 0 && buttonClicked(buttons[0]);
            }
        } else {
            const hasSelected = buttons.filter((b) => b.id === state.selectedId).length === 1;
            hasSelected ? buttonClicked(buttons.filter((b) => b.id === state.selectedId)[0]) : buttons.length > 0 && buttonClicked(buttons[0]);
        }
        setState((st) => ({
            ...st,
            buttons
        }));
    }, [selectedId, buttons]);

    useEffect(() => {
        setState((st) => ({
            ...st,
            isShowScrollArrow: scrollbarVisible(WorkFlowBtnElementscrollWidth)
        }));
    }, [WindowWidths, WorkFlowBtnElementscrollWidth?.scrollWidth]);

    const buttonClicked = (btn) => {
        setState((st) => ({ ...st, selectedId: btn.id }));
        onClick && onClick(btn);
    };

    let history = useHistory();
    let mainWorkflowButtonId = history.location.pathname.split('/')[3];

    const wfbuttonClicked = (btn) => {
        setState((st) => ({ ...st, selectedId: btn.id, showHighlightedButton: true }));
        onClick && onClick(btn);
    };

    // useEffect(() => {
    //     setState((st) => ({ ...st, showHighlightedButton: !(mainWorkflowButtonId == 7) }));
    // }, [history]);

    return (
        <div style={{ overflow: 'hidden', display: 'flex', alignItems: 'center', width: '100%' }} className="BasicButtonGroup-Container">
            {state.isShowScrollArrow && (
                <span onClick={() => scrollPrev()} className="ScrollArrow-Container">
                    <ChevronLeftIcon />
                </span>
            )}
            <div style={state.basicStyle} className={`${className} btn-container`} id="WorkFlow-Btn">
                {state.buttons.map((btn) => {
                    if (btn.id == mainWorkflowButtonId) {
                        return (
                            <SecondaryButton style={{ backgroundColor: 'green' }} key={btn.id} size="small" onClick={() => buttonClicked(btn)}>
                                {btn.label}
                            </SecondaryButton>
                        );
                    }
                    if (btn.id === state.selectedId && state.showHighlightedButton) {
                        if (color === 'secondary') {
                            return (
                                <SecondaryButton key={btn.id} size="small" onClick={() => wfbuttonClicked(btn)}>
                                    {btn.label}
                                </SecondaryButton>
                            );
                        }
                        return (
                            <PrimaryButton key={btn.id} size="small" onClick={() => wfbuttonClicked(btn)}>
                                {btn.label}
                            </PrimaryButton>
                        );
                    } else {
                        return (
                            <DefaultButton key={btn.id} size="small" onClick={() => wfbuttonClicked(btn)}>
                                {btn.label}
                            </DefaultButton>
                        );
                    }
                })}
            </div>
            {state.isShowScrollArrow && (
                <span onClick={() => scrollNext()} className="ScrollArrow-Container">
                    <ChevronRightIcon />
                </span>
            )}
        </div>
    );
};

export default BasicButtonGroup;
